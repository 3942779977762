<template>
  <v-container v-if="PreFooter" class="prefooter">
    <v-row>
      <v-col class="mb-4" cols="12" md="5" offset-md="1" lg="4" offset-lg="2">
        <h2 class="-emo-gradient">{{ PreFooter.preFooterTitle }}</h2>
      </v-col>
      <v-col class="mb-4 pt-md-5" cols="12" md="5" offset-md="1" lg="4" offset-lg="1">
        <p>{{ PreFooter.preFooterText }}</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="5" offset-md="7" lg="4" offset-lg="7">
        <Cta
          :action="PreFooter.preFooterCtaAction"
          :label="PreFooter.preFooterCtaLabel"
          colorSchema="emotional"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GetPreFooter from '@/graphql/GetPreFooter.gql'
import Cta from '@/molecules/Cta'

export default {
  apollo: {
    PreFooter: {
      query: GetPreFooter,
      variables () {
        return {
          slug: 'globals'
        }
      }
    }
  },
  components: {
    Cta
  }
}
</script>

<style lang="scss" scoped>
.prefooter {
  padding-top: rem(80px);
  padding-bottom: rem(60px);
  @include breakpoint('md-and-up') {
    padding-top: rem(160px);
    padding-bottom: rem(120px);
  }
}
</style>
