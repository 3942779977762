<template>
  <transition :name="pageTransition" mode="out-in">
    <div
      v-if="isLoaded"
      class="page"
    >
      <div
        class="page-content -below"
        :class="{
        ['-pb']: disableFooter && disablePreFooter,
        ['-fullFixed']: full
      }">
        <slot name="content"></slot>
      </div>
      <div
        class="footer-container -above"
        v-if="!disablePreFooter || !disablePreFooter"
      >
        <div class="bg-1 -below"></div>
        <div class="bg-2 -below"></div>
        <div class="bg-3 -below"></div>
        <PreFooter v-if="!disablePreFooter" class="-above" />
        <Footer v-if="!disableFooter" class="-above" />
      </div>
    </div>
    <div v-else />
  </transition>
</template>

<script>
import { gsap } from 'gsap'
import PreFooter from '@/organisms/PreFooter'
import Footer from '@/organisms/Footer'

export default {
  name: 'BasePage',
  props: {
    isLoaded: {
      type: Boolean,
      default: true
    },
    disablePreFooter: {
      type: Boolean,
      default: false
    },
    disableFooter: {
      type: Boolean,
      default: false
    },
    full: {
      type: Boolean,
      default: false
    }
  },
  components: {
    PreFooter,
    Footer
  },
  computed: {
    pageTransition () {
      if (this.$route.name === 'Home' || this.$route.name === 'WeAreRegi') {
        return 'pageFade'
      } else {
        return 'pageSlideUp'
      }
    }
  },
  watch: {
    isLoaded: {
      immediate: true,
      handler (value) {
        this.$root.preloader.visible = !value
        this.goToHash()
      }
    }
  },
  methods: {
    goToHash () {
      if (this.isLoaded && this.$route.hash) {
        this.$nextTick(() => {
          gsap.to(window, { duration: 0.5, scrollTo: { y: this.$route.hash, offsetY: 50 } })
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 0 auto;
  z-index: $z-below;
}

.page-content {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  padding-top: rem(76px);

  &.-pb {
    padding-bottom: rem(76px);
  }
}

.footer-container {
  position: relative;
  overflow: hidden;
  background: $color-bg;
}

.bg-1,
.bg-2,
.bg-3 {
  position: absolute;
  pointer-events: none;
}

.bg-1 {
  right: 0;
  top: 0;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  transform: translate(50%, -50%);
  background: $bg-emo-gradient-3;
}

.bg-2 {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: $bg-emo-gradient-2;
}

.bg-3 {
  right: 0;
  bottom: 0;
  width: 50%;
  height: 100%;
  transform: translate(0, 50%);
  background: $bg-emo-gradient-1;
}
</style>
