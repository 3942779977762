import { render, staticRenderFns } from "./PreFooter.vue?vue&type=template&id=0e72b737&scoped=true&"
import script from "./PreFooter.vue?vue&type=script&lang=js&"
export * from "./PreFooter.vue?vue&type=script&lang=js&"
import style0 from "./PreFooter.vue?vue&type=style&index=0&id=0e72b737&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e72b737",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VContainer,VRow})
